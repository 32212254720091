import { Link } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import { Logo, PhoneIcon } from '../media';

const Footer = () => (
    <Box component={'footer'} bgcolor={'#f0f1f2'} mt={16} py={6}>
        <Stack direction={{ xs: 'column-reverse', lg: 'row' }} justifyContent={'space-evenly'} alignItems={'center'} spacing={{ xs: 6, lg: 0 }}>
            <Stack spacing={2} alignItems={'center'} width={'max-content'}>
                <img src={Logo} alt={''} height={120}/>
                <Typography variant={'caption'} align={'center'}>© {(new Date()).getFullYear()} Tous droits réservés | Sirich Gestion Parasitaire</Typography>
            </Stack>
            <Typography variant={'subtitle1'} fontSize={{ xs: 16, md: 18 }} px={2} align={'center'}>
                En bâtiment, c’est la <b>prévention</b> et la <b>lutte intégrée</b> qui rassurent!
            </Typography>
            <Stack spacing={1} sx={{ display: { xs: 'none', lg: 'flex' } }}>
                <Link to={'/'}><Typography color={'black'}>Accueil</Typography></Link>
                <Link to={'/parasites'}><Typography color={'black'}>Identification des parasites</Typography></Link>
                <Link to={'/services'}><Typography color={'black'}>Services</Typography></Link>
                <Link to={'/carriere'}><Typography color={'black'}>Carrière</Typography></Link>
                <Link to={'/contact'}><Typography color={'black'} fontWeight={600}>Contactez-Nous</Typography></Link>
            </Stack>
        </Stack>
    </Box>
)

export default Footer;