import { Box, Typography } from '@mui/material';

const Heading = ({ children }) => (
    <Box sx={{ bgcolor: 'secondary.main' }} px={2} py={{ xs: 4, lg: 6 }}>
        <Typography sx={{
            fontSize: { xs: 24, sm: 32, lg: 48 },
            fontWeight: 650,
            fontVariationSettings: `'wdth' 90, 'YTLC' 540`,
        }} align={'center'}>{children}</Typography>
    </Box>
)

export default Heading;