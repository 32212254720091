import { Box, Button, Stack, Typography } from '@mui/material';
import { Header, Footer } from '../../components';
import Hero from './Hero';
import { LocationIcon } from '../../media';
import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <div>
            <Header/>
            <Hero/>
            <Box sx={{
                mx: { xs: 1, lg: 'auto' },
                mt: 8,
                border: '2px solid black',
                borderColor: 'primary.main',
                width: { lg: '75vw' },
                borderRadius: 1
            }} gap={4} p={4}>
                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent={'space-between'} alignItems={'center'}>
                    <Stack direction={'row'} spacing={2}>
                        <img src={LocationIcon} alt={''} width={32} style={{ flexShrink: 0 }}/>
                        <Typography variant={'subtitle1'}>Saint-Jérôme * Laurentides * Blainville * Boisbriand * Saint Thérèse * Montréal * Ailleurs</Typography>
                    </Stack>
                    <Link to={'./contact'}>
                        <Button variant={'contained'}>Contactez nous</Button>
                    </Link>
                </Stack>
                <Stack maxWidth={512} mt={2}>
                    <Stack direction={'row'} justifyContent={'space-between'} py={0.25}>
                        <Typography variant={'subtitle2'}>Lundi-vendredi</Typography>
                        <Typography variant={'subtitle2'}>8h00-21h00</Typography>
                    </Stack>
                    <Stack direction={'row'} justifyContent={'space-between'} py={0.25}>
                        <Typography variant={'subtitle2'}>Samedi-dimanche</Typography>
                        <Typography variant={'subtitle2'}>8h00-21h00</Typography>
                    </Stack>
                    <Stack direction={'row'} justifyContent={'space-between'} py={0.25}>
                        <Typography variant={'subtitle2'}>Jour férié</Typography>
                        <Typography variant={'subtitle2'}>8h00-19h00</Typography>
                    </Stack>
                </Stack>
            </Box>
            <Footer/>
        </div>
    )
}

export default Home;