import { Grid, Stack, Typography } from '@mui/material';
import { EmailIcon, LocationIcon, PhoneIcon, Whatsapp } from '../../media';

const ContactMethods = () => (
    <Stack direction={{ xs: 'column', lg: 'row' }} justifyContent={'space-evenly'} px={{ xs: 4, md: 24, lg: 4 }} py={12} spacing={{ xs: 3, lg: 0 }}>
        <Stack direction={'row'} alignItems={'flex-start'} spacing={2} width={{ lg: '25%' }}>
            <img src={LocationIcon} alt={''} width={32} style={{ flexShrink: 0 }}/>
            <Typography variant={'subtitle2'}>Saint-Jérôme * Laurentides * Blainville * Boisbriand * Saint Thérèse * Montréal * Ailleurs</Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <img src={PhoneIcon} alt={''} width={32} style={{ flexShrink: 0 }}/>
            <img src={Whatsapp} alt={''} width={32} style={{ flexShrink: 0 }}/>
            <Typography variant={'subtitle1'}>(418) 997-9319</Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <img src={EmailIcon} alt={''} width={32} style={{ flexShrink: 0 }}/>
            <Typography variant={'subtitle1'}>sirichinspection@yahoo.com</Typography>
        </Stack>
    </Stack>
)

export default ContactMethods;