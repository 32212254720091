import { Box, Stack, Typography } from '@mui/material';
import { Footer, Header, Heading } from '../../components';
import { EmailIcon, LocationIcon } from '../../media';

const Carriere = () => {
    return (
        <div>
            <Header/>
            <Heading>Carrière</Heading>
            <Box maxWidth={1024} my={12} mx={'auto'}>
                <Typography variant={'h2'} align={'center'} fontSize={{ xs: 24, md: 40 }}>Nous embauchons!</Typography>
                <Typography variant={'subtitle2'} mt={2} mx={4} fontSize={{ xs: 16, md: 18 }} fontWeight={500}>
                    Si vous êtes intéressés dans le secteur de gestion parasitaire, joindre notre entreprise afin d’améliorer le bien-être des citoyens, veuillez faire
                    parvenir votre curriculum vitae au département de la santé sécurité environnement et en spécifiant la région qui vous intéresse.
                </Typography>
                <Stack direction={'row'} alignItems={'center'} spacing={2} mt={2} mx={4}>
                    <img src={EmailIcon} alt={''} width={32}/>
                    <Typography variant={'subtitle2'} fontSize={{ xs: 16, md: 18 }} fontWeight={500}>
                        Courriel: <a href={'mailto:sirichinspection@yahoo.com'}>sirichinspection@yahoo.com</a>
                    </Typography>
                </Stack>
                <Stack direction={'row'} alignItems={'center'} spacing={2} mt={2} mx={4}>
                    <img src={LocationIcon} alt={''} width={32}/>
                    <Typography variant={'subtitle2'} fontSize={{ xs: 16, md: 18 }} fontWeight={500}>
                        1636 Rue de Villemont, Saint-Jérôme (Québec) J5L 0H5
                    </Typography>
                </Stack>
            </Box>
            <Footer/>
        </div>
    )
}

export default Carriere;