import { Box, Button, Stack, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { CloseIcon, Logo, MenuIcon, PhoneIcon, Whatsapp } from '../media';
import { useEffect, useState } from 'react';

const MenuItem = ({ children, to, outlined }) => {
    const location = useLocation();

    return (
        <Link to={to}>
            <Button sx={{
                px: 2,
                bgcolor: location.pathname === to ? 'primary.main' : undefined,
                color: 'black',
                fontWeight: location.pathname === to ? 750 : undefined,
                ':hover': { backgroundColor: location.pathname === to ? 'primary.main' : undefined }
            }} variant={outlined ? 'outlined' : ''} disableRipple>{children}</Button>
        </Link>
    )
}

const Header = () => {
    const [ scrolled, setScrolled ] = useState(false);
    const [ showMenu, setShowMenu ] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => setScrolled(window.scrollY > 0));

        return window.removeEventListener('scroll', () => setScrolled(window.scrollY > 0));
    }, []);

    return (
        <Box component={'header'} sx={{
            position: 'sticky',
            top: 0,
            background: 'white',
            boxShadow: scrolled ? '0 2px 12px #0004' : '',
            transition: '0.2s ease-in-out',
            zIndex: 50,
        }} pt={2} pb={1}>
            <Stack direction={'row'} justifyContent={'space-evenly'} alignItems={'center'} spacing={1} px={2}>
                <Link to={'../'}>
                    <Box component={'img'} src={Logo} alt={''} height={{ xs: 64, md: 120 }}/>
                </Link>
                <Stack sx={{ display: { xs: 'none', md: 'flex' } }}>
                    <Typography variant={'subtitle1'} align={'center'}>
                        En bâtiment, c’est la <b>prévention</b> et la <b>lutte intégrée</b> qui rassurent!
                    </Typography>
                    <Typography variant={'subtitle2'} align={'center'}>
                        Un service impeccable et à la hauteur de vos attentes! Qualité assurée.
                    </Typography>
                </Stack>
                <Stack alignItems={'center'} spacing={0.5} sx={{ display: { xs: 'none', lg: 'flex' } }}>
                    <Typography variant={'subtitle2'} fontWeight={700}>Disponible et ouvrable 7/7</Typography>
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                        <img src={PhoneIcon} alt={''}/>
                        <img src={Whatsapp} alt={''}/>
                        <Typography variant={'subtitle1'} fontWeight={700} color={'secondary.main'}>
                            (418) 997-9319
                        </Typography>
                    </Stack>
                </Stack>
                <Box
                    component={'img'} src={showMenu ? CloseIcon : MenuIcon} alt={''}
                    onClick={() => setShowMenu(!showMenu)} sx={{ display: { xs: 'block', md: 'none' } }}
                />
            </Stack>
            <Stack
                direction={'row'} justifyContent={'center'} spacing={1}
                mt={2} pt={0.5} sx={{
                    display: {
                        xs: 'none',
                        md: 'flex'
                    },
                    borderTop: '1px solid black',
                    borderTopColor: 'primary.main'
                }}
            >
                <MenuItem to={'/'}>Accueil</MenuItem>
                <MenuItem to={'/parasites'}>Identification des parasites</MenuItem>
                <MenuItem to={'/services'}>Services</MenuItem>
                <MenuItem to={'/carriere'}>Carrière</MenuItem>
                <MenuItem to={'/contact'} outlined>Contactez-Nous</MenuItem>
            </Stack>
            <Stack
                spacing={0.5} py={2} mt={2} alignItems={'center'}
                flexWrap={'wrap'} sx={{
                    display: {
                        xs: showMenu ? 'flex' : 'none',
                        md: 'none'
                    },
                    bgcolor: '#f0f1f2'
                }}
            >
                <Stack direction={'row'} spacing={1} mb={1} alignItems={'center'}>
                    <img src={PhoneIcon} alt={''}/>
                    <img src={Whatsapp} alt={''}/>
                    <Typography variant={'subtitle1'} fontWeight={700} color={'secondary.main'}>
                        (418) 997-9319
                    </Typography>
                </Stack>
                <MenuItem to={'/'}>Accueil</MenuItem>
                <MenuItem to={'/parasites'}>Identification des parasites</MenuItem>
                <MenuItem to={'/services'}>Services</MenuItem>
                <MenuItem to={'/carriere'}>Carrière</MenuItem>
                <MenuItem to={'/contact'} outlined>Contactez-Nous</MenuItem>
            </Stack>
        </Box>
    )
}

export default Header;