export default {
    problematique: [
        `Le déplacement actif correspond à la recherche de l’hôte par le parasite. C’est un déplacement local du lieu de vie vers le lieu de repas.
Le déplacement d’un objet dans lequel elles se sont réfugiées, contribue à la propagation des punaises dans un immeuble locatif, un
quartier, une ville, un pays ou entre les pays. Leur mode de propagation sont surtout transportées dans les valises ou les sacs à main,
vêtements, par les meubles, particulièrement les matelas. Elles peuvent aussi se propager d’un logement à un autre. Ce problème
concerne particulièrement les hôtels, les foyers d’hébergement, les maisons de retraite, les trains de nuit ou bien encore les prisons.
Des victimes n’ayant pas de domicile fixe peuvent aussi propager les infestations, ce sont des personnes à l’hygiène précaire qui ont
l’habitude de vivre avec des punaises de lit. Ces personnes pourront facilement propager des infestations dans des lieux de passage
comme des centres d’hébergement temporaire ou des centres de soins.`,
        `Si vous soupçonnez ou constatez leur présence, nous pouvons vous aider.`
    ],
    planDaction: [
        `Inspection initiale et évaluation du degré et de l’étendue d’infestation.`,
        `Information et sensibilisation des employés (formations spécialisées sur les signes d'activités, incluant des recommandations et
des bonnes pratiques à adopter).`,
        `Conseils pour la préparation des lieux.`,
        `Traitement complet.`,
        `Suivis en fonction du cycle de vie (elle peut vivre plus de 2 ans sans se nourrir).`,
        `Résolution du problème garantie.`
    ],
    rongeurs: {
        rongeurs: [
            `La présence des rongeurs peut être considérée comme un vice caché. Les rongeurs sont des mammifères qui trouvent généralement
refuges dans les habitations, les bâtiments agricoles et les entrepôts, mais ils peuvent également vivre éloignés de nos constructions.`,
            `Leur taille varie d’une espèce à l’autre. Lorsqu’ils viennent à mourir dans nos murs, ils vont pourrir et attirer d’autres insectes, ainsi
vous aurez d’autres nouveaux problèmes à régler. Les rongeurs vont s’introduire dans la majorité des cas, suite à des problèmes de
plomberie. Il est donc très important que lorsqu’on loue, achète ou vend un immeuble résidentiel, restaurant, bureaux ou
commercial ou lorsqu’on fait une rénovation, il faut bien inspecter le bâtiment ou les structures de plomberie pour ne pas laisser les
points d’entrée aux rongeurs. Les rongeurs peuvent aussi rentrer par les vides sanitaires en creusant les tunnels ou une ouverture par le
revêtement extérieur.`,
            `Pour détecter la présence des rongeurs, on va observer certains signes et indices. A titre d’exemple, on entend souvent gratter au niveau
des plafonds ou de la toiture. Vous pourrez également entendre des bruits de déplacement ou observer des excréments, les urines ou
percevoir d’odeurs d’urine, des objets rongés, mélange de musc et d’ammoniaque et des marques de rongement et de frottements ou
empreintes laissés par les nuisibles en circulant dans votre maison et de traces de gras.`,
            `Ce qu’il faut connaitre des rongeurs, ce qu’il peut transporter des tiques, des puces ou des maladies. De ce fait, il est nécessaire de ne
pas tolérer leur présence. <b>Le rat ou la souris, ce n’est pas facile d’en capturer un, mais capturer toute la famille cela devient
beaucoup très difficile.</b> Il faut avant tout repérer quel est la source d’entrée, si on ne trouve pas la source d’entrée. Le problème ne
sera réglé que de manière temporaire.`,
            `Alors n’attendez plus et prenez contact téléphonique avec l’un de nos experts au (418) 997-9319 afin d’obtenir l’estimation de votre
prix gratuit. Vous pouvez aussi prendre rendez-vous directement dans notre site internet`,
        ],
        regime: `Les rats et les souris appartiennent tous deux à l’ordre des rongeurs, les Rodentiens. Il s’agit de mammifères végétariens ou omnivores à la
dentition caractéristique. Les rongeurs ont ainsi deux principales activités dans la journée, ronger pour se limer les dents et manger pour se
nourrir.`,
        comportement: `Les rats sont extrêmement prudents et observateurs. Le rat brun est néophobe:  il se méfie de tout ce qui est nouveau dans son environnement sur
une durée de 3 à 4 jours. Le rat brun mémorise ses parcours ou ses situations. 
Les souris construisent leur nid à l’intérieur des bâtiments dans des amas de chiffon, de papier ou autres matériaux doux. Les souris sont
généralement actives la nuit. Dans le nid, les souris communiquent entre elles par des cris aigus.`,
        degats: `Ces rongeurs endommagent par ailleurs les contenants, qui laissent alors échapper les aliments. Vérifiez également si les portes, les câbles et le
matériel ne sont pas endommagés. Les conduites de plomberie percées. Ils rongent les fils électriques. Les rats et les souris transmettent des
maladies en souillant la nourriture de leur urine ou de leur déjection.`,
        prevention: [
        `Trouver et bloquer la ou les voies d’infiltration`,
        `Installer des trappes mécaniques à l’intérieur`,
        `Installer des postes d’appâtage avec rodenticides à l’extérieur`,
        `Colmater ou grillager toute ouverture de plus de 1,25 cm (1/2 pouce)`
        ],
        lutte: `À l’extérieur, disposer un poste d’appâtage contenant un rodenticide près du terrier ou de l’endroit où circule le ou les rats.
À L’intérieur, employer des pièges englués dans des boîtes en carton et des trappes mécaniques le long des surfaces verticales.`,
    },
    ratBrun: `On appelle aussi le rat de Norvège ou rat d'''égout ou rat gris. Le rat brun ou surmulot (Rattus novergicus). Il peut avoir les yeux rouges
ou noirs. Le rat brun est un excellent nageur, mais un piètre grimpeur. Il mesure de 20 à 28 cm, queue écaillée de 16 à 28 cm, pour un
poids pouvant aller jusqu’à 500 g ! Le rat est omnivore. Il vit essentiellement dans les lieux humides, c’est pourquoi il creuse terriers
et galeries dans les sous-sols et aux abords des habitations, et qu’on peut le rencontrer dans les égouts.`,
    ratNoir: `Le rat est reconnaissable à son pelage noir à noisette, avec le dessous du corps plus clair. Le rat brun a une queue d’une vingtaine de centimètres,
plus longue que son corps. Il est omnivore, mais semble préférer les graines. Il est un mauvais nageur, mais un excellent grimpeur. Il vit donc
généralement dans les arbres, arbustes grimpants ou autres végétaux touffus, et infestera les greniers et les étages supérieurs des immeubles.`,
    souris: `La souris a de grandes oreilles, sa couleur varie entre le brun pâle et le gris foncé, le ventre étant plus pâle. Elle mesure entre 6 et 19 cm de long de
la tête à la queue comprise. Elle se nourrit d’à peu près tout ce qui est comestible. Elle vit en milieux urbain et rural. Elle trouve généralement
refuge dans les habitations, les bâtiments agricoles et les entrepôts. Durant l’été, la souris domestique peut vivre à l’extérieur dans un terrier le
long des édifices et des trottoirs, ou dans les broussailles. À l’approche de la saison froide la souris recherche un abri à l’intérieur. Les excréments
ressemblent à du riz. Elle vit entre de 2 à 3 ans maximum. Le nombre de portées par an est de 4 à 8 et de 6-12 petits par portée.`,
    blatte: {
        caracteristques: `La blatte germanique est la plus répandue au Québec. Elle mesure environ 12 mm de long et a de longues antennes fines et souples. Son corps est
brun avec deux rayures foncées sur le thorax. Les ailes sont observées en fonction de leur développement.
La femelle pond de 8 à 48 œufs dans une capsule fixée à l’extrémité de son abdomen. Elle la porte pendant environ 28 jours pour ensuite la cacher
dans une fissure ou la fixer au mur ou au plafond. Les jeunes blattes naîtront ensuite. La durée de vie de la femelle atteint parfois les six mois, elle
pourra ainsi obtenir entre 20 000 et 30 000 descendants au bout d’une année.`,
        habitat: `Les blattes sont plus actives la nuit et déteste la lumière du jour. Elles se nourrissent des mêmes aliments que l’homme. Leurs excréments et
sécrétions de la bouche, souillent la nourriture. Lorsque la vaisselle et les aliments sont souillés par les coquerelles, les blattes peuvent transmettre
des maladies.`,
        prevention: [
            `Maintenir propre votre environnement les plus vulnérables (cuisine, salles de bains et chambres à coucher)`,
            `Débarrasser les débris alimentaires et l’humidité `,
            `Vérifier les nouveaux contenants de denrées alimentaires en revenant de l’épicerie`,
            `Sortir de la maison les boîtes de cartons ayant servi à transporter les aliments`,
            `Sceller les fissures dans les armoires de la cuisine`,
            `Les pièges englués munis d'appâts pour les blattes sont un meilleur moyen de détection en vue de confirmer l’identification du parasite.`,
            `Appliquer un insecticide sur le périmètre des pièces, en dessous des appareils ménagers et autour de la tuyauterie des pièces où les insectes
ont été vus.`,
            `Appliquer un insecticide en poudre dans les vides de structure des pièces où les insectes ont été vus.`,
            `Si vous êtes aux prises avec un problème de blattes, nous vous conseillons fortement la visite d’un de nos techniciens.`,
        ]
    },
    fCharpentiere: {
        caracteristques: `La fourmi charpentière, de couleur noire, est l’un des invertébrés qui vit en colonie. La population est constituée des mâles, des reines, des
ouvrières et des soldats. Après un accouplement, les mâles ailés meurent. Les femelles sont soit des reines ou des ouvrières. Les futures reines sont
ailées. La reine est généralement beaucoup plus grosse que les ouvrières. Les ouvrières mesurent entre 6 et 13 mm de long. Elles prennent une
apparence différente selon leurs fonctions : nourrices, butineuses, soldates, etc. Les soldats sont des femelles stériles, sans ailes. Leur taille est
légèrement supérieure à celle des ouvrières. Leur tête et leurs pièces buccales sont plus grosses.`,
        cycle: `​L'accouplement débute au printemps. Chaque jeune reine s'accouple en vol avec un mâle. Ensuite, la reine perd ses ailes et cherche un espace pour
installer sa colonie. L’espace considéré est notamment : tronc d'arbre, grosse souche ou pièce de bois.
En mai, toutes les fourmis ailées sortent du nid et s'envolent. Les mâles meurent peu après l'accouplement. Les futures reines se mettent en quête
d'un site convenable pour établir une colonie.`,
        habitat: `Les fourmis charpentières vivent dans le bois humide ou en décomposition. Le milieu forestier constitue leur habitat d'origine. Certaines
s'établissent dans les maisons ou près des habitations.`,
        alimentation: `Les fourmis charpentières se nourrissent de miellat, d'insectes et de petits invertébrés, ainsi que des sucs de plantes. Dans nos maisons, les fourmis
peuvent manger presque tout ce dont les humains se nourrissent.`,
        mode: `La présence de fourmis dans les résidences résulte de l'introduction d'une jeune reine ailée prête à fonder une colonie, de l'entrée de bois de
chauffage infesté, ou encore de la migration d'une colonie vivant à l'extérieur.`,
        prevention: `À l'intérieur des résidences il faut garder les lieux propres et en bon état. Toute fuite d'eau de la toiture ou de la tuyauterie doit être réparée
rapidement. Il faut éviter d'entreposer le bois pourri qui entoure la résidence. À l'extérieur, il faut enlever tout le bois pourri qui entoure la
résidence. Il est important, lorsqu'il y a une infestation, de localiser le ou les nids en observant le comportement des fourmis.`,
    },
    fPaves: {
        caracteristques: `La fourmi des pavés (Tetramorium caespitum) mesure environ 3 mm de longueur. Sa coloration tend vers le brun foncé.
Les fourmis sont des insectes sociaux qui vivent en colonies. La reine est la seule femelle du groupe à avoir des ailes au début de sa vie. Lors d’un
moment donné, selon les espèces, les mâles et les femelles ailées sortent de la colonie pour s’accoupler. On dit alors qu’il y a essaimage. Dans le
cas de la fourmi des pavés, l’essaimage a lieu surtout au début de l’été, mais on peut voir les formes sexuées (fourmis ailées) durant toute la saison
estivale. À ce moment, la femelle fécondée se dépose au sol et trouve un site idéal pour fonder une colonie. Quelques jours plus tard, elle
commence à pondre entre 5 à 20 œufs par jour.`,
        habitat: `La fourmi des pavés fait son nid dans le sol près ou sous les trottoirs, les allées et souvent sous les fondations des bâtiments. L’extérieur du nid,
lorsqu’il est visible, a la forme d’un petit monticule de sable sortant parmi les fissures du pavé.`,
        alimentation: `La fourmi des pavés a un régime alimentaire varié. Elle peut se nourrir du miellat produit par certains insectes, de substances sucrées, de matières
grasses, de graines ou d’autres insectes. Les ouvrières peuvent s’infiltrer par les fissures présentes dans la dalle de béton pour chercher de la
nourriture à l’intérieur des bâtiments. Il peut arriver qu’un nid se trouve dans la bâtisse, près d’une source de chaleur (radiateur, conduit de chaleur,
etc.).`,
        lutte: `Si une colonie s’est installée dans la bâtisse, la visite d’un technicien est conseillée. L’intervention débutera avec un appâtage spécifique à cette
fourmi et se complètera par une application localisée d’insecticide afin d’éliminer les derniers individus qui circulent. Dans les cas plus
compliqués, il faut envisager une intervention par injection d’un insecticide sous la dalle de béton. Il sera alors nécessaire de percer la dalle à des
intervalles réguliers afin de pouvoir introduire le dispositif permettant l’injection du produit.`,
    },
    fPharaon: {
        description: `Les adultes de la colonie appartiennent à des castes différentes. Les ouvrières sont des femelles stériles qui mesurent entre 1,5 et 2,5 mm de long.
Les reines, plus grosses et plus foncées, mesurent entre 3,5 et 5 mm. On compte plusieurs reines dans une même colonie. Les mâles mesurent
environ 2,8 mm. Les reines et les mâles adultes portent des ailes à l'émergence. Contrairement à beaucoup d’espèces de fourmis, il y a plusieurs
reines dans une colonie de fourmis pharaons. Même s’il y a des fourmis ailées, il n’y a pas de vol nuptial pour cette espèce. L’accouplement se
produit dans le nid et de nouvelles colonies se forment par bourgeonnement. À ce moment, une ou plusieurs reines et quelques ouvrières quittent le
nid avec des larves et des pupes pour migrer dans un endroit propice au développement d’une nouvelle colonie. Dans des conditions optimales,
une colonie peut atteindre 300 000 individus.`,
        habitat: `La fourmi pharaon est d’origine chaude. La colonie a besoin d’un milieu chaud et humide pour s’y installer. Elles vont généralement s’installer
dans un vide ou une fissure de la structure (sinon le vide d’une armoire, derrière les plinthes ou les cadres de fenêtre, etc.).`,
        infestation: `Une colonie peut s’établir dans la maison, si on transporte une partie de nid cachée dans une boîte. Les colonies sont très mobiles et peuvent se déplacer facilement lorsque les conditions du milieu changent. À l’intérieur, les ouvrières circulent souvent près d’une source d’humidité. Donc, on les retrouve généralement dans la cuisine ou la salle de bain, circulant sur la tuyauterie. À l’extérieur, les ouvrières circulent autour des fenêtres et des portes. Ces fourmis circulent surtout vers la fin de journée, même si on peut les retrouver durant le jour. La fourmi pharaon cherche le sucré, les matières grasses et les insectes pour nourrir la colonie. S’il y a un stress dans la colonie, elle va souvent se diviser pour donner plusieurs petites colonies réparties dans la structure.`,
        lutte: `De par leur nature, résoudre un problème de fourmis pharaons dans la structure peut s’avérer très complexe. L’application d’un insecticide résiduel
de contact est déconseillée, car il contribue à diviser et le problème peut se répartir dans plusieurs pièces. L’intervention initiale doit se faire au
moyen d’un appât insecticide. Par contre, les appâts vendus au détail ne sont pas toujours efficaces puisqu’ils ne sont pas spécifiques à la fourmi
pharaon. Il faut s’armer de patience pour éliminer cette fourmi de notre demeure !`,
        prevention: `Éliminer les résidus de nourriture qui se retrouve au sol ou sur les comptoirs (entretien ménager). Colmater les fissures dans les dalles de béton et
rendre hermétique le plus possible les contours extérieurs des fenêtres et portes donnant près du sol.`,
    },
}