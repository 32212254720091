import { Header, Footer, Heading } from '../../components';

import ContactMethods from './ContactMethods';
import ContactForm from './ContactForm';
import { Stack, Typography } from '@mui/material';

const Contact = () => {
    return (
        <div>
            <Header/>
            <Heading>Contact Sirich</Heading>
            <ContactMethods/>
            <Stack maxWidth={512} mt={2} mx={'auto'} px={2} mb={8}>
                <Stack direction={'row'} justifyContent={'space-between'} py={0.25}>
                    <Typography variant={'subtitle2'}>Lundi-vendredi</Typography>
                    <Typography variant={'subtitle2'}>8h00-21h00</Typography>
                </Stack>
                <Stack direction={'row'} justifyContent={'space-between'} py={0.25}>
                    <Typography variant={'subtitle2'}>Samedi-dimanche</Typography>
                    <Typography variant={'subtitle2'}>8h00-21h00</Typography>
                </Stack>
                <Stack direction={'row'} justifyContent={'space-between'} py={0.25}>
                    <Typography variant={'subtitle2'}>Jour férié</Typography>
                    <Typography variant={'subtitle2'}>8h00-19h00</Typography>
                </Stack>
            </Stack>
            <ContactForm/>
            <Footer/>
        </div>
    )
}

export default Contact;