import { useState } from 'react';
import { Box, Button, Dialog, Grid, TextField, Typography } from '@mui/material';

const ContactForm = () => {
    const [ name, setName ] = useState();
    const [ phone, setPhone ] = useState();
    const [ message, setMessage ] = useState();
    const [ comments, setComments ] = useState();
    const [ captcha, setCaptcha ] = useState();
    const [ captchaNumbers ] = useState([ Math.floor(Math.random() * 9 + 1), Math.floor(Math.random() * 9 + 1) ]);

    const [ openDialog, setOpenDialog ] = useState(false);

    const handleContact = async () => {
        const body = {
            from: 'info@djeumene.ca',
            to: 'sirichextermination@gmail.com',
            subject: 'Sirich Inspection',
            body: `
                <h3>Sirich Inspection</h3>
                <p><b>Nom:</b> ${name}</p>
                <p><b>Telephone:</b> ${phone}</p>
                <p><b>Courriel:</b> ${message}</p>
                <p><b>Commentaires:</b> ${comments}</p>
            `
        }

        if (name && phone && message && comments && +captcha === captchaNumbers[0] + captchaNumbers[1]) {
            // await fetch('http://localhost:3040/contact', {
            await fetch('https://api.sirichgestionparasitaire.ca/contact', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body)
            });

            setOpenDialog(true);

            setName('');
            setPhone('');
            setMessage('');
            setComments('');
            setCaptcha('');
        }
    }

    return (
        <Box bgcolor={'primary.light'} pr={4} py={10}>
            <Typography variant={'h2'} align={'center'}>Contactez-Nous</Typography>
            <Grid container maxWidth={1024} spacing={2} mx={'auto'} mt={1.5}>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={'Nom'} onChange={event => setName(event.target.value)} fullWidth
                        value={name} sx={{ bgcolor: 'white' }}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={'Téléphone'} onChange={event => setPhone(event.target.value)} fullWidth
                        value={phone} sx={{ bgcolor: 'white' }}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={'Courriel'} onChange={event => setMessage(event.target.value)} fullWidth
                        value={message} sx={{ bgcolor: 'white' }}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={'Commentaires'} onChange={event => setComments(event.target.value)} fullWidth multiline
                        value={comments} minRows={4} maxRows={8} sx={{ bgcolor: 'white' }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={`${captchaNumbers[0]} + ${captchaNumbers[1]} = ?`} value={captcha}
                        onChange={event => setCaptcha(event.target.value)} fullWidth sx={{ bgcolor: 'white' }}
                    />
                </Grid>
                <Grid item container xs={12} justifyContent={'center'}>
                    <Button variant={'contained'} size={'large'} onClick={handleContact} sx={{ px: 4 }}>Envoyer</Button>
                </Grid>
            </Grid>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth={768}>
                <Box p={4}>
                    <Typography variant={'subtitle2'}>Votre courriel a été bien reçu et un spécialiste vous contactera dans les brefs délais. Merci de nous avoir contacté.</Typography>
                    <Typography variant={'subtitle2'} mt={1} mb={2}>La direction.</Typography>
                    <Button variant={'contained'} color={'secondary'} onClick={() => setOpenDialog(false)}>Fermer</Button>
                </Box>
            </Dialog>
        </Box>
    )
}

export default ContactForm;