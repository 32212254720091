export default {
    assistance: {
        assistance: `Une municipalité adopte des règlements sur l'environnement, la salubrité, la sécurité et les nuisances. Elle définit aussi, ce qui est insalubre, ce qui
constitue une nuisance ou présente un risque en matière de sécurité. Par conséquent, elle le fait supprimer et impose des amendes aux personnes
qui les créent ou refusent de les supprimer. À titre d’exemples, laisser se propager ou se multiplier, à l’intérieur d’un immeuble, des insectes, des
rongeurs, des moisissures ou autres parasites ou accumulation d’objets hétéroclites de manière susceptible de présenter un danger pour la santé ou la sécurité de
ses occupants ou du public. Toutefois, tout propriétaire, locataire, occupant ou responsable d’une habitation a des droits et devoirs face à l’une des
problématiques sus-citées.
Que vous cherchiez à vendre un immeuble résidentiel ou commercial;
Que vous cherchiez à acheter un immeuble résidentiel ou commercial;
Que vous cherchiez une résidence en location;
Que vous offrez une résidence pour location;
Que vous soyez en location dans un bâtiment résidentiel ou commercial.`,
        services: [
            `Prévention des punaises de lit, blattes, rats, souris, fourmis, moisissures et autres;`,
            `Lutte intégrée contre les punaises de lit, blattes, rats, fourmis, souris et autres;`,
            `Assistance technique des clients auprès des tribunaux spécialisés;`,
            `Expertise et conseils.`,
        ],
        protection: [
            `Identifier l’insecte nuisible.`,
            `Déterminer s’il pose un problème.`,
            `Choisir une solution, qui peut inclure un contrôle physique ou mécanique, ou l’utilisation d’un produit antiparasitaire.`,
            `Inspecter les lieux, pour déterminer si la solution a porté fruit.`,
            `Prendre des mesures préventives pour s’assurer que les parasites ne reviendront pas.`,
        ]
    },
    sante: `La santé est un état de bien-être physique, mental et social complet et ne consiste pas seulement en une absence de maladie ou d'infirmité. Le bien-
être social est un aspect important de votre santé. L’accumulation de déchets dans l’immeuble présente des impacts sur notre environnement et par
conséquent sur notre santé et sécurité. La mauvaise utilisation des produits anti-parasitaires contribue à la prolifération et la propagation des
nuisibles. Le manque d’information et de sensibilisation sur ces produits et stratégies inappropriées peut causer des effets directs ou indirects, à
court ou à long terme sur notre santé et celle du public sans que vous n’ayez la possibilité d’établir le lien de causalité.
Il est donc très important que lorsqu’on loue, achète ou vend un immeuble résidentiel, restaurant, bureaux ou commercial ou lorsqu’on fait
une rénovation, il est intéressant d’inspecter le bâtiment en vue de diagnostiquer les dangers physiques, chimiques et biologiques.
Il peut arriver qu’aucune activité de présence de nuisibles soit observée dans le bâtiment. Ceci ne constitue en rien une absence de dangers dans
votre logement car l’absence de preuves n’est pas la preuve de l’absence de risques. Afin de résider ou de vivre dans un esprit tranquille à
travers des solutions adéquates et durables, faites analyser les risques potentiels directs et indirects de la présence ou de l’absence d’indices,
d’indicateurs et d’activités de vermines, de moisissures ou de rongeurs dans votre unité d’habitation avant tout achat ou location. N’attendez plus
et prenez contact téléphonique avec l’un de nos experts au (418) 997-9319 afin d’obtenir l’estimation de votre prix gratuit. Vous pouvez aussi
prendre rendez-vous directement dans notre site internet:`
}