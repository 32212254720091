import { createTheme } from '@mui/material';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0, // up to phone
            sm: 512, // up to small tablet
            md: 768, // up to tablet
            lg: 1024, // up to laptop
            xl: 1440 // larger than laptop
        }
    },
    palette: {

        primary: {
            light: '#85cc0340',
            main: '#85cc03',
            dark: '#5fab04',
            // dark: '#3f8a04',
        },
        secondary: {
            main: '#fb9706',
            dark: '#b85900',
            // dark: '#b85900',
        }
    },
    components: {
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    borderRadius: 2
                },
                sizeLarge: {
                    fontSize: 18
                },
            }
        },
        MuiTouchRipple: {
            defaultProps: {
                style: {
                    opacity: 0.25,
                    filter: 'blur(4px)'
                }
            }
        }
    },
    typography: {
        fontFamily: 'Roboto FlexVariable',
        h1: {
            fontSize: 44,
            fontWeight: 650,
            fontVariationSettings: `'wdth' 80, 'YTLC' 550`,
            lineHeight: 1,
        },
        h2: {
            fontSize: 40,
            fontWeight: 600,
            fontVariationSettings: `'wdth' 90, 'YTLC' 550`,
            lineHeight: 1,
        },
        h3: {
            marginTop: 24,
            marginBottom: 8,
            fontWeight: 700,
        },
        h4: {
            marginTop: 16,
            marginBottom: 4,
            fontWeight: 700,
        },
        h5: {
            marginTop: 8,
            fontWeight: 600,
        },
        subtitle1: {
            fontSize: 20,
            fontWeight: 550,
            fontVariationSettings: `'wdth' 100, 'YTLC' 530`,
            lineHeight: 1.5
        },
        subtitle2: {
            fontSize: 16,
            fontWeight: 550,
            fontVariationSettings: `'wdth' 100, 'YTLC' 530`,
            lineHeight: 1.5
        },
        button: {
            fontSize: 16,
            fontWeight: 550,
            letterSpacing: 0.4,
            textTransform: 'none',
            fontVariationSettings: `'wdth' 90, 'YTLC' 550`,
        }
    }
});

export { theme };