import { Box, Stack, Typography } from '@mui/material';
import { Header, Footer, Heading } from '../../components';
import {
    Blatte1, Blatte5,
    Fourmi1,
    Fourmi2,
    Fourmi3,
    Fourmi4, Fourmi5, Fourmi6, Punaises4,
    Rat1,
    Rat2,
    Rat3,
    Rat4,
    Souris1,
    Souris2,
    Souris3
} from '../../media';
import data from './ParasitesData';

const renderText = data => {
    if (typeof data === 'string')
        return <Typography>{data}</Typography>
    else
        return data.map((text, i) => <Typography key={i} dangerouslySetInnerHTML={{__html: text}}></Typography>)
}

const renderList = data => {
    return <ul>
        {data.map((text, i) => <Typography component={'li'} key={i}>{text}</Typography>)}
    </ul>
}

const Parasites = () => {
    return (
        <div>
            <Header/>
            <Heading>Identification des parasites</Heading>
            <Box maxWidth={1024} mx={'auto'} px={2}>
                <Typography variant={'h4'} mt={8} mb={2}>Punaises de lit</Typography>
                <Box component={'img'} src={Punaises4} alt={''} borderRadius={2} boxShadow={'0 0 4px #0004'} width={'100%'}/>
                <Typography variant={'h4'} mt={8}>Propagation</Typography>
                {renderText(data.problematique)}
                <Typography variant={'h4'}>Plan d’action</Typography>
                {renderList(data.planDaction)}
                <Typography variant={'h3'}>Rongeurs</Typography>
                {renderText(data.rongeurs.rongeurs)}
                <Box mt={6}>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} mx={-1} width={'100%'} alignItems={'stretch'}>
                        <Box component={'img'} src={Rat4} alt={''} borderRadius={2} boxShadow={'0 0 4px #0004'} width={0} flexGrow={1}/>
                        <Box component={'img'} src={Rat3} alt={''} borderRadius={2} boxShadow={'0 0 4px #0004'} width={0} flexGrow={1}/>
                    </Stack>
                    <Typography variant={'h4'}>Le rat brun (Rattus norvegicus)</Typography>
                    {renderText(data.ratBrun)}
                </Box>
                <Box mt={6}>
                    <Box component={'img'} src={Rat2} alt={''} mx={-1} borderRadius={2} boxShadow={'0 0 4px #0004'}/>
                    <Typography variant={'h4'}>Le rat noir (Rattus rattus)</Typography>
                    {renderText(data.ratNoir)}
                </Box>
                <Box mt={6}>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} mx={-1} width={'100%'} alignItems={'stretch'}>
                        <Box component={'img'} src={Souris2} alt={''} borderRadius={2} boxShadow={'0 0 4px #0004'} width={0} flexGrow={1}/>
                        <Box component={'img'} src={Souris3} alt={''} borderRadius={2} boxShadow={'0 0 4px #0004'} width={0} flexGrow={1}/>
                    </Stack>
                    <Typography variant={'h4'}>Souris domestique (Mus musculus domesticus)</Typography>
                    {renderText(data.souris)}
                </Box>
                <Box mt={6}>
                    <Typography variant={'h5'}>Régime alimentaire des rongeurs</Typography>
                    {renderText(data.rongeurs.regime)}
                    <Typography variant={'h5'}>Comportement des rongeurs</Typography>
                    {renderText(data.rongeurs.comportement)}
                    <Typography variant={'h5'}>Dégâts dans une maison lorsque non contrôlé</Typography>
                    {renderText(data.rongeurs.degats)}
                    <Typography variant={'h5'}>Prévention</Typography>
                    {renderList(data.rongeurs.prevention)}
                    <Typography variant={'h5'}>Lutte</Typography>
                    {renderText(data.rongeurs.lutte)}
                </Box>
                <Box mt={6}>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} mx={-1} alignItems={'stretch'}>
                        <Box component={'img'} src={Blatte1} alt={''} borderRadius={2} boxShadow={'0 0 4px #0004'} width={0} flexGrow={1}/>
                        <Box component={'img'} src={Blatte5} alt={''} borderRadius={2} boxShadow={'0 0 4px #0004'} width={0} flexGrow={1}/>
                    </Stack>
                    <Typography variant={'h4'}>Blattes ou coquerelles</Typography>
                    <Typography variant={'h5'}>Caractéristiques</Typography>
                    {renderText(data.blatte.caracteristques)}
                    <Typography variant={'h5'}>Habitat</Typography>
                    {renderText(data.blatte.habitat)}
                    <Typography variant={'h5'}>Prévention</Typography>
                    {renderText(data.blatte.prevention[0])}
                    {renderList(data.blatte.prevention.slice(1, 8))}
                    {renderText(data.blatte.prevention[8])}
                </Box>
                <Typography variant={'h3'}>Fourmis</Typography>
                <Box mt={2}>
                    <Box component={'img'} src={Fourmi2} alt={''} mx={-1} borderRadius={2} boxShadow={'0 0 4px #0004'}/>
                    <Typography variant={'h4'}>Fourmis charpentières</Typography>
                    <Typography variant={'h5'}>Caractéristiques</Typography>
                    {renderText(data.fCharpentiere.caracteristques)}
                    <Typography variant={'h5'}>Cycle de vie et comportement</Typography>
                    {renderText(data.fCharpentiere.cycle)}
                    <Typography variant={'h5'}>Habitat</Typography>
                    {renderText(data.fCharpentiere.habitat)}
                    <Typography variant={'h5'}>Alimentation</Typography>
                    {renderText(data.fCharpentiere.alimentation)}
                    <Typography variant={'h5'}>Mode d'infestation</Typography>
                    {renderText(data.fCharpentiere.mode)}
                    <Typography variant={'h5'}>Prévention</Typography>
                    {renderText(data.fCharpentiere.prevention)}
                </Box>
                <Box mt={6}>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} mx={-1} width={'100%'} alignItems={'stretch'}>
                        <Box component={'img'} src={Fourmi4} alt={''} borderRadius={2} boxShadow={'0 0 4px #0004'} width={0} flexGrow={1}/>
                        <Box component={'img'} src={Fourmi6} alt={''} borderRadius={2} boxShadow={'0 0 4px #0004'} width={0} flexGrow={1}/>
                    </Stack>
                    <Typography variant={'h4'}>Fourmis des pavés</Typography>
                    <Typography variant={'h5'}>Caractéristiques</Typography>
                    {renderText(data.fPaves.caracteristques)}
                    <Typography variant={'h5'}>Habitat</Typography>
                    {renderText(data.fPaves.habitat)}
                    <Typography variant={'h5'}>Alimentation</Typography>
                    {renderText(data.fPaves.alimentation)}
                    <Typography variant={'h5'}>Lutte</Typography>
                    {renderText(data.fPaves.lutte)}
                </Box>
                <Box mt={6}>
                    <Box component={'img'} src={Fourmi1} alt={''} mx={-1} borderRadius={2} boxShadow={'0 0 4px #0004'}/>
                    <Typography variant={'h4'}>Fourmis pharaons</Typography>
                    <Typography variant={'h5'}>Description et développement</Typography>
                    {renderText(data.fPharaon.description)}
                    <Typography variant={'h5'}>Habitat</Typography>
                    {renderText(data.fPharaon.habitat)}
                    <Typography variant={'h5'}>Infestation</Typography>
                    {renderText(data.fPharaon.infestation)}
                    <Typography variant={'h5'}>Lutte</Typography>
                    {renderText(data.fPharaon.lutte)}
                    <Typography variant={'h5'}>Prévention</Typography>
                    {renderText(data.fPharaon.prevention)}
                </Box>
                <Box mt={6}>
                    <Typography variant={'h4'}>Autres nuisibles</Typography>
                    <Typography>
                        La compagnie Sirich Gestion parasitaire traite également les problèmes des parasites suivants:
                    </Typography>
                    <Typography>
                        <b>Araignées</b> * <b>Dermites du Lard</b> * <b>Attagènes noirs du tapis</b> * <b>Pyrales indiennes de la farine</b>
                        * <b>Mouches domestiques</b> * <b>Mouches à drains</b> * <b>Mouches à fruits</b> * <b>Cloportes</b> * <b>Abeilles
                        domestiques et autres</b>.
                    </Typography>
                </Box>
            </Box>
            <Footer/>
        </div>
    )
}

export default Parasites;