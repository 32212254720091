import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import Parasites from './pages/Parasites';
import Services from './pages/Services';
import Carriere from './pages/Carriere';
import Contact from './pages/Contact';

const AppRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route path={'/'} element={<Home/>}/>
                <Route path={'/parasites'} element={<Parasites/>}/>
                <Route path={'/services'} element={<Services/>}/>
                <Route path={'/carriere'} element={<Carriere/>}/>
                <Route path={'/contact'} element={<Contact/>}/>
            </Routes>
        </Router>
    )
}

export default AppRoutes;