import { ThemeProvider } from '@mui/material';

import '@fontsource/roboto-flex/variable-full.css';
import './index.scss';

import { theme } from './theme';
import AppRoutes from './Routes';

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <AppRoutes/>
        </ThemeProvider>
    )
}

export default App;