import { Box, Typography } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { Blatte4, Fourmi1, Fourmi3, Fourmi5, Punaises4, Rat1, Souris1 } from '../../media';

const CarouselItem = ({ image, text }) => (
    <Box sx={{
        background: `url(${image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '60vh',
    }}
         p={2}
         display={'flex'}
         justifyContent={'flex-start'}
         alignItems={'flex-end'}
    >
        <Typography variant={'h1'} color={'white'} sx={{
            ml: 2,
            fontSize: {
                xs: 24,
                md: 44
            },
            fontVariationSettings: `'wdth' 120`,
            textShadow: '0 2px 8px #0004',
        }}>{text}</Typography>
    </Box>
)

const HeroCarousel = () => (
    <Carousel
        animation={'slide'}
        interval={3000}
        navButtonsAlwaysVisible
        sx={{
            position: 'relative',
            display: 'flex',
            m: { xs: 1, lg: 'auto' },
            width: { lg: '75vw' },
            borderRadius: 1
        }}
        indicatorContainerProps={{
            style: {
                position: 'absolute',
                right: 0,
                display: 'flex',
                flexDirection: 'column',
                padding: '6px 2px 6px 6px',
                width: 'max-content',
                borderRadius: '8px 0 0 8px',
                background: 'white',
                zIndex: 10
            }
        }}
    >
        <CarouselItem image={Rat1} text={'Rats'}/>
        <CarouselItem image={Souris1} text={'Souris'}/>
        <CarouselItem image={Blatte4} text={'Blattes'}/>
        <CarouselItem image={Punaises4} text={'Punaises de lit'}/>
        <CarouselItem image={Fourmi3} text={'Fourmis charpentières'}/>
        <CarouselItem image={Fourmi5} text={'Fourmis des pavés'}/>
        <CarouselItem image={Fourmi1} text={'Fourmis pharaons'}/>
    </Carousel>
)

export default HeroCarousel;