import { Box, Stack, Typography } from '@mui/material';
import { Header, Footer, Heading } from '../../components';
import data from './ServicesData';
import { Action1, Action2, Action3, Assistance, Fourmi4, Fourmi6, Punaises1, Punaises3, Search1 } from '../../media';

const renderList = data => {
    return <ul>
        {data.map((text, i) => <Typography component={'li'} key={i}>{text}</Typography>)}
    </ul>
}

const Services = () => {
    return (
        <div>
            <Header/>
            <Heading>Nos Services</Heading>
            <Box maxWidth={1024} mx={'auto'} px={2}>
                <Box py={8}>
                    <Typography variant={'h4'}>Secteur résidentiel et commercial (services offerts)</Typography>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} mt={4} mx={-1} width={'100%'} alignItems={'center'}>
                        <Box component={'img'} src={Action1} alt={''} borderRadius={1} width={0} flexGrow={1}/>
                        <Box component={'img'} src={Action2} alt={''} borderRadius={1} width={0} flexGrow={1}/>
                        <Box component={'img'} src={Action3} alt={''} borderRadius={1} width={0} flexGrow={1}/>
                        <Box component={'img'} src={Assistance} alt={''} borderRadius={1} width={0} flexGrow={1}/>
                    </Stack>
                    <Typography mt={4}>{data.assistance.assistance}</Typography>
                    <Typography>Nous offrons nos services dans les domaines suivants:</Typography>
                    {renderList(data.assistance.services)}
                    <Typography>La meilleure protection contre les parasites incommodants est une solution de « lutte intégrée ». La lutte intégrée comprend les étapes suivantes :</Typography>
                    {renderList(data.assistance.protection)}
                </Box>
                <Box py={8}>
                    <Typography variant={'h4'}>Santé</Typography>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} mt={4} mx={-1} width={'100%'} alignItems={'center'}>
                        <Box component={'img'} src={Punaises1} alt={''} borderRadius={1} width={0} flexGrow={1}/>
                        <Box component={'img'} src={Punaises3} alt={''} borderRadius={1} width={0} flexGrow={1}/>
                        <Box component={'img'} src={Search1} alt={''} borderRadius={1} width={0} flexGrow={1}/>
                    </Stack>
                    <Typography mt={4}>{data.sante}</Typography>
                </Box>
            </Box>
            <Footer/>
        </div>
    )
}

export default Services;